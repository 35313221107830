// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cases-calaton-studio-branding-web-js": () => import("./../../../src/pages/cases/calaton-studio-branding-web.js" /* webpackChunkName: "component---src-pages-cases-calaton-studio-branding-web-js" */),
  "component---src-pages-cases-calaton-studio-web-js": () => import("./../../../src/pages/cases/calaton-studio-web.js" /* webpackChunkName: "component---src-pages-cases-calaton-studio-web-js" */),
  "component---src-pages-cases-calaton-systems-branding-web-js": () => import("./../../../src/pages/cases/calaton-systems-branding-web.js" /* webpackChunkName: "component---src-pages-cases-calaton-systems-branding-web-js" */),
  "component---src-pages-cases-calaton-systems-web-js": () => import("./../../../src/pages/cases/calaton-systems-web.js" /* webpackChunkName: "component---src-pages-cases-calaton-systems-web-js" */),
  "component---src-pages-cases-js": () => import("./../../../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-cases-linguamill-js": () => import("./../../../src/pages/cases/linguamill.js" /* webpackChunkName: "component---src-pages-cases-linguamill-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

